import React, {Component} from 'react';

class Card extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="shadow p-3 mb-5 bg-white rounded">
                {this.props.children}
            </div>
        )
    }
}

export default Card;
