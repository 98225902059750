import React, {Component, Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";

class FreeEmolumentDetail extends Component{

    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        }
        this.toggleChange = this.toggleChange.bind(this)
    }

    toggleChange() {
        this.setState({ toggle: !this.state.toggle })
    }

  render(){
    return this.props.freeemoluments.length >= 1 ?(
        <Fragment>
            <div className="border-bottom py-2">
                <div className="d-flex bd-highlight">
                    <div className="bd-highlight mr-2 my-auto">
                        <button
                            className="btn btn-light btn-sm rounded-circle"
                            onClick={this.toggleChange}
                        >
                            <FontAwesomeIcon
                                icon={
                                    this.state.toggle
                                        ? 'angle-down'
                                        : 'angle-right'
                                }
                            />
                        </button>
                    </div>
                    <h5><span className="badge badge-category mt-2">Emoluments du notaire libres</span></h5>
                    {this.state.toggle ? null : (
                        <div className="ml-auto bd-highlight my-2">
                            <b>{this.props.freeemoluments.reduce((a, b) => a + b.amount, 0).toFixed(2)}€ HT</b>
                        </div>
                    )}
                </div>
            </div>

            {this.state.toggle ? this.props.freeemoluments.map((disc) =>
                <div className="border-bottom py-2" key={disc.id}>
                    <div className="d-flex align-items-center">
                        <div className="mr-2">
                            {disc.name}
                        </div>
                        <div className="ml-auto">
                            <b>{parseInt(disc.amount, 10).toFixed(2)}€</b> HT
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            ) :null }

     </Fragment>
    ) : null
  }
}


function mapStateToProps(state) {
    const freeemoluments = state.quotation.quotation.free_emolument_quotations
    return {freeemoluments};
}

export default connect(mapStateToProps, null)(FreeEmolumentDetail);


