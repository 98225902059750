import React, {Component} from 'react';
import LogoNn from "../../../assets/images/logo-square.svg";
import {APINotreNotaire} from "../../services/Api";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { email } = this.state;
        if (email) {
            APINotreNotaire.post("/email_links", {email: email}).then(response => {
                this.setState({ submitted: true })
            });
        }
    }



    render(){
        const { email, submitted } = this.state;
        return(
            <div className="bg-light pt-md-5" style={{height: "100vh"}}>
                <div className="container pt-md-5">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6">
                            <div className="bg-white p-5 shadow rounded mt-5">
                                {submitted &&
                                <div className="alert alert-primary">Le lien a été envoyé, RDV dans votre boite mail</div>
                                }
                                <LogoNn alt="" width='80' height='80'/>
                                <p className="h2 mt-4">Se connecter<br/> à son compte client</p>
                                <p>Vous recevrez dans votre boite e-mail le lien de connexion à votre compte perso.</p>
                                <form name="form" onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label>E-mail :</label>
                                        <input className="form-control mb-4" style={{marginLeft: 0}} type="text" name="email" value={email} onChange={this.handleChange} />
                                        {submitted && !email &&
                                        <div className="help-block">Email est requis</div>
                                        }
                                    </div>
                                    <div className="mb-3">
                                        <button type="submit" className="btn btn-secondary">Recevoir mon lien de connexion</button>
                                    </div>
                                    <a href="https://app.notre-notaire.fr">Accéder à votre compte notaire</a>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



export default Login;
