import {APIAuthNotreNotaire} from "./Api";

export const userService = {
    login,
};

function login(token) {
    return APIAuthNotreNotaire.post("/sign_in?token="+token)
}



