import React, {Component, Fragment} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { quotationActions } from '../../../../actions/quotation.actions';
import {history} from "../../../../helpers/history"

class ActionQuotation extends Component {

  refuseQuotationCall(idquotation, idquotationresquest) {
    this.props.refuseQuotation(idquotation, idquotationresquest)
    history.push('/')
  }


  acceptQuotationCall(id) {
    this.props.acceptQuotation(id)
  }


    render() {
    const {quotation} = this.props.quotation;
        return (
          <Fragment>
            <div className="row mt-4">
              <div className="col text-center">
                <button className="btn btn-lg btn-outline-danger"  onClick={() => this.refuseQuotationCall(quotation.id, quotation.quotation_request.id)}>Refuser ce devis</button>
              </div>
              <div className="col text-center">
                <button className="btn btn-lg btn-secondary" onClick={() => this.acceptQuotationCall(quotation.id)}>Accepter ce devis</button>
              </div>
            </div>
          </Fragment>
        )
    }
}

function mapStateToProps(state) {
  return {quotation: state.quotation};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ refuseQuotation: quotationActions.refuseQuotation, acceptQuotation: quotationActions.acceptQuotation }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionQuotation);

