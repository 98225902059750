import React, {Component} from 'react';
import Card from "../../../../components/Cards";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class DossierCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            detailDevis: false,
            detailInfo: false,
            emolumentNotaire: 0,
            taxes: 0,
            honoraires: 0,
            frais: 0,
            emolumentLibre: 0,
            remise: 0,
        }

        this.detailDevisChange = this.detailDevisChange.bind(this)
        this.detailInfoChange = this.detailInfoChange.bind(this)
    }

    componentDidMount() {
        this.props.folder.quotation.fixed_emolument_quotations.map(
            (emolument) =>
                this.setState({
                    emolumentNotaire:
                        this.state.emolumentNotaire + emolument.total,
                })
        )
        this.props.folder.quotation.taxe_quotations.map((taxe) =>
            this.setState({
                taxes: this.state.emolumentNotaire + taxe.amount,
            })
        )
        this.props.folder.quotation.consulting_quotations.map((consulting) =>
            this.setState({
                honoraires: this.state.emolumentNotaire + consulting.honoraire,
            })
        )
        this.props.folder.quotation.other_cost_quotations.map((other_cost) =>
            this.setState({
                frais: this.state.emolumentNotaire + other_cost.amount,
            })
        )
        this.props.folder.quotation.free_emolument_quotations.map(
            (emolument) =>
                this.setState({
                    emolumentLibre:
                        this.state.emolumentNotaire + emolument.amount,
                })
        )
        this.props.folder.quotation.discount_quotations.map((emolument) =>
            this.setState({
                remise: this.state.emolumentNotaire + emolument.amount,
            })
        )
    }

    detailDevisChange() {
        this.setState({ detailDevis: !this.state.detailDevis })
    }

    detailInfoChange() {
        this.setState({ detailInfo: !this.state.detailInfo })
    }

    render() {
        return (
            <Card key={this.props.folder.id}>
                <div className="row">
                    <div className="col-6">
                        <h4 className="text-primary mb-3">Dossier avec Maître {this.props.folder.user.fiche.nom} <span
                            className="badge badge-category">{this.props.folder.category.name} </span></h4>
                    </div>

                </div>
                <div className="py-2 border-bottom">
                    <h4><FontAwesomeIcon icon="user" className="mr-2" />Information notaire :</h4>
                    <div className="d-flex align-items-center">
                        <div className="mr-2">
                            <img src={this.props.folder.user.fiche.photo.url} width="60" height="60"
                                 className="rounded-circle" alt=""/>
                        </div>
                        <div className="align-self-center">
                            <strong>Maître {this.props.folder.user.fiche.nom}</strong><br/>
                            <p className="mb-0">Adresse: {this.props.folder.user.fiche.adresse} </p>
                            <p className="mb-0">Téléphone: 0{this.props.folder.user.fiche.telephone} </p>
                            <p className="mb-0">E-mail: {this.props.folder.user.email} </p>

                        </div>
                        <div className="ml-auto">
                            <br/>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
                <div className="py-2 border-bottom">
                    <h4>
                        <FontAwesomeIcon icon="info-circle" className="mr-2" />
                        Information sur le devis
                    </h4>
                    {this.state.detailInfo
                        ? this.props.folder.quotation_request.reponse_qualifications.map(
                            (qualif) => (
                                <p className="mb-3" key={qualif.id}>
                                    <b>{qualif.qualification.question}</b>{' '}
                                    {qualif.reponse}
                                </p>
                            )
                        )
                        : null}
                    <button
                        className="btn btn-sm btn-light"
                        onClick={this.detailInfoChange}
                    >
                        {this.state.detailInfo
                            ? 'Moins de détails'
                            : 'Plus de détails'}
                    </button>
                </div>
                <div className="py-2 border-bottom">
                    <h4>
                        <FontAwesomeIcon icon="file-invoice" className="mr-2" />
                        Devis
                    </h4>
                    {this.state.detailDevis ? (
                        <div>
                            {this.state.emolumentNotaire === 0 ? null : (
                                <div className="border-bottom py-2 mb-2">
                                    <div className="d-flex bd-highlight">
                                        <p className="bd-highlight badge badge-primary my-auto">
                                            Emoluments du notaire
                                        </p>
                                        <div className="ml-auto bd-highlight">
                                            <b>
                                                {this.state.emolumentNotaire}€
                                                HT
                                            </b>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.taxes === 0 ? null : (
                                <div className="border-bottom py-2 mb-2">
                                    <div className="d-flex bd-highlight">
                                        <p className="bd-highlight badge badge-danger my-auto">
                                            Taxes et droit
                                        </p>
                                        <div className="ml-auto bd-highlight">
                                            <b>{this.state.taxes}€ HT</b>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.honoraires === 0 ? null : (
                                <div className="border-bottom py-2 mb-2">
                                    <div className="d-flex bd-highlight">
                                        <p className="bd-highlight badge badge-info my-auto">
                                            Honoraires Conseil
                                        </p>
                                        <div className="ml-auto bd-highlight">
                                            <b>{this.state.honoraires}€ HT</b>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.frais === 0 ? null : (
                                <div className="border-bottom py-2 mb-2">
                                    <div className="d-flex bd-highlight">
                                        <p className="bd-highlight badge badge-other my-auto">
                                            Autres frais
                                        </p>
                                        <div className="ml-auto bd-highlight">
                                            <b>{this.state.frais}€ HT</b>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.emolumentLibre === 0 ? null : (
                                <div className="border-bottom py-2 mb-2">
                                    <div className="d-flex bd-highlight">
                                        <p className="bd-highlight badge badge-category my-auto">
                                            Emolument libre
                                        </p>
                                        <div className="ml-auto bd-highlight">
                                            <b>
                                                {this.state.emolumentLibre}€ HT
                                            </b>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.remise === 0 ? null : (
                                <div className="border-bottom py-2 mb-2">
                                    <div className="d-flex bd-highlight">
                                        <p className="bd-highlight badge badge-secondary my-auto">
                                            Remises
                                        </p>
                                        <div className="ml-auto bd-highlight">
                                            <b>{this.state.remise}€ HT</b>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : null}
                    <button
                        className="btn btn-sm btn-light"
                        onClick={this.detailDevisChange}
                    >
                        {this.state.detailDevis
                            ? 'Moins de détails'
                            : 'Plus de détails'}
                    </button>
                    <div className="pb-2">
                        <div className="d-flex align-items-center">
                            <h4>Total Devis</h4>
                            <div className="ml-auto">
                                <br />
                                <h5>
                                    <b>
                                        {this.props.folder.quotation.total_ht.toFixed(
                                            2
                                        )}
                                        €{' '}
                                    </b>
                                    HT
                                </h5>
                                <h5>
                                    <b>
                                        {this.props.folder.quotation.total_ttc.toFixed(
                                            2
                                        )}
                                        €{' '}
                                    </b>
                                    TTC
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }
}

export default DossierCard;
