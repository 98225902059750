export const quotationConstants = {
  //List
   GETQUOTATIONS_REQUEST_LOADING: 'GET_QUOTATIONS_REQUEST_LOADING',
   GETQUOTATIONS_REQUEST_SUCCESS: 'GET_QUOTATIONS_REQUEST_SUCCESS',
   GETQUOTATIONS_REQUEST_FAILURE: 'GET_QUOTATIONS_REQUEST_FAILURE',
   //Detail
   GETQUOTATION_REQUEST_LOADING: 'GET_QUOTATION_REQUEST_LOADING',
   GETQUOTATION_REQUEST_SUCCESS: 'GET_QUOTATION_REQUEST_SUCCESS',
   GETQUOTATION_REQUEST_FAILURE: 'GET_QUOTATION_REQUEST_FAILURE',
   //refuse
   REFUSEQUOTATION_REQUEST_LOADING: 'REFUSE_QUOTATION_REQUEST_LOADING',
   REFUSEQUOTATION_REQUEST_SUCCESS: 'REFUSE_QUOTATION_REQUEST_SUCCESS',
   REFUSEQUOTATION_REQUEST_FAILURE: 'REFUSE_QUOTATION_REQUEST_FAILURE',
   //Accepte
   ACCEPTQUOTATION_REQUEST_LOADING: 'ACCEPT_QUOTATION_REQUEST_LOADING',
   ACCEPTQUOTATION_REQUEST_SUCCESS: 'ACCEPT_QUOTATION_REQUEST_SUCCESS',
   ACCEPTQUOTATION_REQUEST_FAILURE: 'ACCEPT_QUOTATION_REQUEST_FAILURE',
};
