import React, {Component, Fragment} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class EmolumentCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        }
        this.toggleChange = this.toggleChange.bind(this)
    }

    toggleChange() {
        this.setState({ toggle: !this.state.toggle })
    }

    renderTranche(emolument){
        if(emolument.fix_emol_quot_tranches){
            return(
                emolument.fix_emol_quot_tranches.map((value) =>
                    <div className="border-bottom py-2" key={value.id}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                Tranche {value.libelle} €
                            </div>
                            <div className="ml-auto">
                                <b>{value.amount.toFixed(2)}€ </b>HT
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                )
            )
        }
    }

    render() {

        const {emolument} = this.props

        return (
            <Fragment>
                <div className="border-bottom py-2">
                    <div className="d-flex bd-highlight">
                        <div className="bd-highlight mr-2 my-auto">
                            <button
                                className="btn btn-light btn-sm rounded-circle"
                                onClick={this.toggleChange}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        this.state.toggle
                                            ? 'angle-down'
                                            : 'angle-right'
                                    }
                                />
                            </button>
                        </div>
                        <h5>
                         <span className="bd-highlight badge badge-primary mt-2">
                            Emoluments du notaire: {emolument.name}
                        </span>
                        </h5>
                        {this.state.toggle ? null : (
                            <div className="ml-auto bd-highlight my-2">
                                <b>{emolument.total.toFixed(2)}€ HT</b>
                            </div>
                        )}
                    </div>
                </div>
                {this.state.toggle
                    ?
                    <Fragment>
                        <div className="border-bottom py-2">
                            <div className="d-flex align-items-center">
                                <div className="mr-2">
                                    Emoluments du notaire prix de base
                                </div>
                                <div className="ml-auto">
                                    <b>{emolument.prix_fixe.toFixed(2)}€ </b>HT
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                        {this.renderTranche(emolument)}
                        <div className="border-bottom py-2">
                            <div className="d-flex align-items-center">
                                <div className="mr-2">
                                    <b>Total émolument notaire</b>
                                </div>
                                <div className="ml-auto">
                                    <b>{emolument.total.toFixed(2)}€ </b>HT
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                    </Fragment> : null }
            </Fragment>
        )
    }
}

export default EmolumentCard;
