import React, {Component} from 'react';

class Title extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
          <div className="mb-4">
            <div className="d-flex flex-column flex-md-row justify-content-between">
              <div>
                <p className="h1 mb-1">{this.props.title}</p>
              </div>
            </div>
          </div>
        )
    }
}

export default Title;
