import { quotationConstants } from '../constants/quotation.constants';
import { quotationService } from '../services/quotation.services';
import {history} from "../helpers/history"
import {res} from "../helpers/dispatch";

export const quotationActions = {
    getQuotation,
    refuseQuotation,
    acceptQuotation
}

// Get the quotation detail
function getQuotation(id) {
    return dispatch => {
        dispatch(res.loading(quotationConstants.GETQUOTATION_REQUEST_LOADING))
        quotationService.getQuotation(id)
            .then (
                response => {
                    let devis = response.data
                    dispatch(
                        res.success(
                            quotationConstants.GETQUOTATION_REQUEST_SUCCESS,
                            devis
                        )
                    )
                }
            ).catch (
                error => {
                    res.failure(quotationConstants.GETQUOTATION_REQUEST_FAILURE, error)
                }
        );
    };
}

// Refuser un devis
function refuseQuotation(idquotation, idquotationresquest) {
    return dispatch => {
        dispatch(res.loading(quotationConstants.REFUSEQUOTATION_REQUEST_LOADING))

        quotationService.refuseQuotation(idquotation)
            .then (
                response => {
                    let data = {
                        idquotation : idquotation,
                        idquotationresquest : idquotationresquest
                    }
                    dispatch(
                        res.success(
                            quotationConstants.REFUSEQUOTATION_REQUEST_SUCCESS,
                            data
                        )
                    )
                }
            ).catch (
                error => {
                    res.failure(quotationConstants.REFUSEQUOTATION_REQUEST_FAILURE, error)
                }
        );
    };
}

// Accepter un devis
function acceptQuotation(id) {
    return dispatch => {
        dispatch(res.loading(quotationConstants.ACCEPTQUOTATION_REQUEST_LOADING))

        quotationService.acceptQuotation(id)
            .then (
                response => {
                    history.push('/dossiers')
                }
            ).catch (
                error => {
                    res.failure(quotationConstants.ACCEPTQUOTATION_REQUEST_FAILURE, error)
                }
        );
    };
}
