import React, {Component, Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {bindActionCreators} from "redux";
import {quotationActions} from "../../../../../actions/quotation.actions";
import {connect} from "react-redux";
import EmolumentCard from "./EmolumentCard";

class EmolumentDetail extends Component{

    constructor(props) {
        super(props)

    }




  render(){
    return this.props.emoluments.length >= 1 ? (
        this.props.emoluments.map((emolument)=>
            <EmolumentCard emolument={emolument} key={emolument.id}/>
      )
    ) : null
  }
}

function mapStateToProps(state) {
    const emoluments = state.quotation.quotation.fixed_emolument_quotations
    return {emoluments};
}

export default connect(mapStateToProps, null)(EmolumentDetail);



