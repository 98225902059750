import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import reduxPromise from 'redux-promise';
import logger from 'redux-logger';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { reducer as formReducer } from 'redux-form';
import {PrivateRoute} from './components/PrivateRoute';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


//Style
import '../assets/stylesheets/application.scss';

//import { store } from './helpers/stores';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { history } from './helpers/history';

//Internet Explorer
import 'react-app-polyfill/ie9';

//Fontawesom
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

// REDUCERS IMPORTS
import { authentication } from './reducers/auth';
import { alert } from './reducers/alert';
import {requestQuotations} from './reducers/request_quotations';
import { Quotations, Quotation } from './reducers/quotations';
import { Folders } from './reducers/folders';

// CONTAINERS IMPORT
import Login from "./containers/Auth/Login";
import Validate from "./containers/Auth/Validate";
import DetailDevis from './containers/Devis/DetailDevis/DetailDevis';
import ProfilPage from './containers/Profil/ProfilPage';
import ListDossiers from './containers/Dossiers/ListDossiers/ListDossiers'
import ListeDemandes from "./containers/Demandes/ListeDemandes";

const reducers = combineReducers({
  authentication: authentication,
  alert: alert,
  form: formReducer,
  request_quotations: requestQuotations,
  quotations: Quotations,
  quotation: Quotation,
  folders: Folders
});


const loggerMiddleware = createLogger();
const middlewares = applyMiddleware(reduxPromise, logger, thunkMiddleware,loggerMiddleware);

ReactDOM.render(
  <Provider store={createStore(reducers, {}, middlewares)}>
    <Router history={history}>
      <Switch>
        <Route path="/login" exact component={Login}/>
        <Route path="/validate/:token" exact component={Validate}/>
        <PrivateRoute exact path="/" component={ListeDemandes}/>
        <PrivateRoute exact path="/devis/:id" component={DetailDevis}/>
        <PrivateRoute path="/dossiers" exact component={ListDossiers}/>
        <PrivateRoute path="/profil" exact component={ProfilPage}/>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);

