import {APINotreNotaire} from "./Api";

export const requestQuotationService = {
    getAll,
};

function getAll() {

    return APINotreNotaire.get("/get_client_quotation_requests")
}
