import axios from 'axios';
import {history} from "../helpers/history";


export const APINotreNotaire = axios.create({
    baseURL: process.env.API_URL,
    timeout: 10000,
    headers: {
        'Content-Type':'application/json',
        'Accept': 'application/json'
    }
});

APINotreNotaire.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');

        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },

    error => Promise.reject(error)
);

APINotreNotaire.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        history.push('/login')
        return Promise.reject(error)
    }
)

export const APIAuthNotreNotaire = axios.create({
    baseURL: process.env.API_AUTH,
    timeout: 1000,
    headers: {
        'Content-Type':'application/json',
        'Accept': 'application/json'
    }
});
