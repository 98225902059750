import React, {Component} from 'react';
import NavBarItem from './NavBarItem';
import LogoSquare from "../../../assets/images/nn-square.png";
import {Link} from "react-router-dom";

class NavBar extends Component {

  constructor(props) {
    super(props);
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  render(){
    return(
      <div className="dash-sidebar col-12 col-md-3 col-xl-2 p-0">
          <nav className="dash-nav collapse" id="dash-nav">
            <div className="my-3 ml-2 d-flex">
              <div style={{height: "60"}}>
                <img src={'/'+LogoSquare} style={{height: "60", width: "60"}}/>
              </div>
              <div className="ml-3 mt-2">
                <h4 className="mb-1 text-primary">Notre-Notaire</h4>
                <p><Link to="/profil">{this.user.lastname.toUpperCase()} {this.user.firstname}</Link></p>
              </div>
            </div>

            <ul className="nav flex-column">
              <NavBarItem link='/' icon="file-contract" content='Demandes' />
              <NavBarItem link='/dossiers' icon="folder" content='Dossiers' />
              <NavBarItem link='/login' icon="sign-out-alt" content='Déconnexion' />
            </ul>
          </nav>
      </div>
    )
  }
}



export default NavBar;
