import React, {Component, Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {connect} from "react-redux";



class TaxeDetail extends Component{

    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        }
        this.toggleChange = this.toggleChange.bind(this)
    }

    toggleChange() {
        this.setState({ toggle: !this.state.toggle })
    }

  render(){
    return(
        <Fragment>
            <div className="border-bottom py-2">
                <div className="d-flex bd-highlight">
                    <div className="bd-highlight mr-2 my-auto">
                        <button
                            className="btn btn-light btn-sm rounded-circle"
                            onClick={this.toggleChange}
                        >
                            <FontAwesomeIcon
                                icon={
                                    this.state.toggle
                                        ? 'angle-down'
                                        : 'angle-right'
                                }
                            />
                        </button>
                    </div>
                    <h5 className="mt-2"><span className="badge badge-danger">Taxes et droits</span></h5>
                    {this.state.toggle ? null : (
                        <div className="ml-auto bd-highlight my-2">
                            <b>{this.props.taxe.reduce((a, b) => a + b.amount, 0).toFixed(2)}€ HT</b>
                        </div>
                    )}
                </div>
            </div>
            {this.state.toggle ? <Fragment>
                {this.props.taxe.map((taxe) =>
                    <div className="border-bottom py-2" key={taxe.id}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                {taxe.name}
                            </div>
                            <div className="ml-auto">
                                <b>{taxe.amount.toFixed(2)}€ </b>HT
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                )}
                <div className="border-bottom py-2">
                    <div className="d-flex align-items-center">
                        <div className="mr-2">
                            <b>Total Taxe et droits</b>
                        </div>
                        <div className="ml-auto">
                            <b>{this.props.taxe.reduce((a, b) => a + b.amount, 0).toFixed(2)}€ </b>HT
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </Fragment> :null}
     </Fragment>
    )
  }
}

function mapStateToProps(state) {
    const taxe = state.quotation.quotation.taxe_quotations
    return {taxe};
}

export default connect(mapStateToProps, null)(TaxeDetail);



