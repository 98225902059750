import { userConstants } from '../constants/user.constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = {
  loading: null,
  user: user ? user : {},
  error: null
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_LOADING:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        user: action.payload.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error

  };
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}
