import React, {Component, Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator} from '@fortawesome/free-solid-svg-icons';

class TaxeDetail extends Component{

  render(){
    if(!this.props.total_ht){
      return(
        <p></p>
      )
    }
    return(
          <div className="border-bottom py-2">
            <div className="d-flex align-items-center">
              <h4>Total Devis</h4>
              <div className="ml-auto">
              <br/>
                <h5><b>{this.props.total_ht.toFixed(2)}€ </b>HT</h5>
                <h5><b>{this.props.total_ttc.toFixed(2)}€ </b>TTC</h5>
              </div>
            </div>
            <div>
          </div>
        </div>
    )
  }
}





export default TaxeDetail;
