import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar as faStarFull} from '@fortawesome/free-solid-svg-icons';
import {faStar} from '@fortawesome/free-regular-svg-icons';
import {DateTime} from 'luxon'
import {bindActionCreators} from "redux";
import {quotationActions} from "../../../../actions/quotation.actions";
import {connect} from "react-redux";

class NotaireQuotation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            detailAvis: false
        }

        this.detailAvisChange = this.detailAvisChange.bind(this)
    }

    detailAvisChange() {
        this.setState({detailAvis: !this.state.detailAvis})
    }

    render() {
        const {quotation, loading} = this.props.quotation;
        return loading ? null : (
            <div className="shadow p-3 bg-white rounded mb-5">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <img src={quotation.user.fiche.photo.url} width="80" height="80"
                                     className="rounded-circle" alt=""/>
                            </div>
                            <div className="align-self-center">
                                <p className="mb-0"><strong>Maître {quotation.user.fiche.nom}</strong></p>
                                <p className="text-muted mb-0"><b>Devis
                                    créer:</b> {DateTime.fromISO(quotation.created_at).toLocaleString(DateTime.DATE_MED)}
                                </p>
                                <p className="text-muted">Ville: {quotation.nom_ville}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="dash-rating mb-0">
                            Note globale:
                            {[...Array(quotation.rating)].map(() =>
                                <FontAwesomeIcon icon={faStarFull} style={{color: '#ffce00'}}/>
                            )}
                            {[...Array(5 - quotation.rating)].map(() =>
                                <FontAwesomeIcon icon={faStar} style={{color: '#ffce00'}}/>
                            )}
                        </div>
                        <p className="text-muted mb-1">Nombre d'avis: {quotation.note_size}</p>
                        <button className="btn btn-sm btn-light"
                                onClick={this.detailAvisChange}>{this.state.detailAvis ? "Cacher les avis" : "Voir les avis"}</button>
                    </div>
                    <div className="col-sm-2">
                        <div className="dash-rating mb-2">
                            Qualité :
                            {[...Array(quotation.average_rating_quality)].map(() =>
                                <FontAwesomeIcon icon={faStarFull} style={{color: '#ffce00'}}/>
                            )}
                            {[...Array(5 - quotation.average_rating_quality)].map(() =>
                                <FontAwesomeIcon icon={faStar} style={{color: '#ffce00'}}/>
                            )}
                        </div>
                        <div className="dash-rating mb-2">
                            Accueil :
                            {[...Array(quotation.average_rating_acceuil)].map(() =>
                                <FontAwesomeIcon icon={faStarFull} style={{color: '#ffce00'}}/>
                            )}
                            {[...Array(5 - quotation.average_rating_acceuil)].map(() =>
                                <FontAwesomeIcon icon={faStar} style={{color: '#ffce00'}}/>
                            )}
                        </div>

                    </div>
                    <div className="col-sm-2">
                        <div className="dash-rating mb-2">
                            Ponctualité :
                            {[...Array(quotation.average_rating_ponctualite)].map(() =>
                                <FontAwesomeIcon icon={faStarFull} style={{color: '#ffce00'}}/>
                            )}
                            {[...Array(5 - quotation.average_rating_ponctualite)].map(() =>
                                <FontAwesomeIcon icon={faStar} style={{color: '#ffce00'}}/>
                            )}
                        </div>
                        <div className="dash-rating mb-2">
                            Prestation :
                            {[...Array(quotation.average_rating_communication)].map(() =>
                                <FontAwesomeIcon icon={faStarFull} style={{color: '#ffce00'}}/>
                            )}
                            {[...Array(5 - quotation.average_rating_communication)].map(() =>
                                <FontAwesomeIcon icon={faStar} style={{color: '#ffce00'}}/>
                            )}
                        </div>
                    </div>
                </div>
                {this.state.detailAvis ?
                    <div className="mt-2">
                        {quotation.avis.map(avis_one =>
                            <div className="border rounded mb-2 p-2" key={avis_one.id}>
                                <p className="mb-1">Sujet
                                    d'intervention: <b>{avis_one.sujet_notation}</b><br/>
                                    {avis_one.commentaire_notation}</p>
                            </div>
                        )}
                    </div>
                    :null}
                {quotation.comment ?
                    <div className="alert alert-secondary mt-3"><strong>Message du notaire:</strong>
                        <i>{quotation.comment}</i></div> : null}
                {quotation.commitment_date ?
                    <div className="alert alert-primary mt-3"><strong>{quotation.commitment_date}</strong> :
                        Temps de réalisation de l'acte par le notaire une fois les pièces fournis</div> : null}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {quotation : state.quotation };
}

export default connect(mapStateToProps, null)(NotaireQuotation);

