import React, {Component, Fragment} from 'react';
import Title from "../../../components/Title";
import DossierCard from "./DossierCard/DossierCard";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { folderActions } from '../../../actions/folder.actions';
import Loader from "../../../components/Loader";

class ListDossiers extends Component {

  constructor(props) {
      super(props);
  }

  componentDidMount() {
       this.props.getFolders();
   }


    render() {
      const {loading, error} = this.props.folders

      if (loading) {
          return (
              <div className="">
                  <Loader/>
              </div>
          )
      }

      if (error) {
          return (
              <div className="">
                  Error
              </div>
          )
      }

      if(!this.props.folders.folders){
        return(<p>Vous n'avez pas encore de dossier</p>)
      }
        return (
            <Fragment>
                <Title title="Liste de vos dossiers"/>
                  {this.props.folders.folders.map((folder)=>
                    <DossierCard folder={folder} key={folder.id}/>
                  )}
            </Fragment>
        )
    }
}
function mapStateToProps(state) {
  return {
    folders: state.folders,
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getFolders: folderActions.getFolders }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListDossiers);


