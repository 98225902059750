import { folderConstants } from '../constants/folder.constants';


export function Folders(state = [], action) {
  switch (action.type) {
    case folderConstants.GETFOLDERS_REQUEST_LOADING:
      return {
        ...state,
        loading: true
      };
    case folderConstants.GETFOLDERS_REQUEST_SUCCESS:
      return {
        ...action.payload,
        loading: false,
        error: null,
      };
    case folderConstants.GETFOLDERS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
    };
    default:
      return state
  }
}
