import {requestQuotationConstants} from "../constants/request_quotation.constants";
import {requestQuotationService} from "../services/resquest_quotation.service";
import {res} from "../helpers/dispatch";

export const requestQuotationsActions = {
    getAll,
}

function getAll() {
    return dispatch => {
        dispatch(res.loading(requestQuotationConstants.LOADING))

        requestQuotationService.getAll()
            .then (
                response => {
                    let demandeDevis = response.data
                    dispatch(
                        res.success(
                            requestQuotationConstants.GET_ALL_SUCCESS,
                            demandeDevis
                        )
                    )
                }
            ).catch (
                error => {
                    res.failure(requestQuotationConstants.GET_ALL_FAILURE, error)
                }
        );
    };
}

