import React, {Component} from 'react';
import EmolumentDetail from './EmolumentDetail/EmolumentDetail';
import TaxeDetail from './TaxeDetail/TaxeDetail';
import TotalDetail from './TotalDetail/TotalDetail';
import ConsultingDetail from './ConsultingDetail/ConsultingDetail';
import FreeEmolumentDetail from './FreeEmolumentDetail/FreeEmolumentDetail';
import DiscountDetail from './DiscountDetail/DiscountDetail';
import OthersCostsDetail from './OthersCostsDetail/OthersCostsDetail';
import {bindActionCreators} from "redux";
import {quotationActions} from "../../../../actions/quotation.actions";
import {connect} from "react-redux";

class DetailQuotation extends Component{


  render(){

      const {quotation} = this.props.quotation;
    return(
      <div className="shadow p-3 mb-2 bg-white rounded">
          <p className="h2 text-center mb-2">Devis</p>
          <EmolumentDetail/>
          <TaxeDetail/>
          <ConsultingDetail/>
          <FreeEmolumentDetail/>
          <OthersCostsDetail />
          <DiscountDetail />
          <TotalDetail total_ht={quotation.total_ht} total_ttc={quotation.total_ttc}/>
      </div>
    )
  }
}

function mapStateToProps(state) {
    return {quotation : state.quotation };
}


export default connect(mapStateToProps, null)(DetailQuotation);


