import {APINotreNotaire} from "./Api";

export const quotationService = {
    getQuotations,
    getQuotation,
    refuseQuotation,
    acceptQuotation
};

function getQuotations() {
    return APINotreNotaire.get("/get_client_quotations")
}

function getQuotation(id) {
    return APINotreNotaire.get(`/get_client_quotation/${id}`)
}

function refuseQuotation(id) {
    return APINotreNotaire.post(`/refuse_client_quotations/${id}`)
}

function acceptQuotation(id) {
    return APINotreNotaire.post(`/accept_client_quotations/${id}`)
}
