import { userConstants } from '../constants/user.constants';
import { userService } from '../services/user.service';
import {history} from "../helpers/history"
import { alertActions } from './alert.actions';


export const userActions = {
    login,
    logout
};

function login(token) {
    return dispatch => {
        dispatch(loading());

        userService.login(token)
            .then (
                response => {
                    let token = response.headers.authorization
                    localStorage.setItem('token', token)
                    let user = response.data
                    localStorage.setItem('user', JSON.stringify(user))
                    dispatch(success(user));
                    history.push('/')
                }
            ).catch (
                error => {
                    dispatch(failure(error.response.status));
                }
        );
    };
}

function loading() {
    return {
        type: userConstants.LOGIN_LOADING
    }
}

function success(user, token) {
    return {
        type: userConstants.LOGIN_SUCCESS,
        payload: {
            user
        }
    }
}

function failure(error) {
    return {
        type: userConstants.LOGIN_FAILURE,
        payload: {
            error
        }
    }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

