import React, {Component, Fragment} from 'react';
import Title from "../../../components/Title";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { quotationActions } from '../../../actions/quotation.actions';
import DetailDevisHeader from './DetailDevisHeader/DetailDevisHeader';
import DetailQuotation from './DetailQuotation/DetailQuotation';
import NotaireQuotation from './NotaireQuotation/NotaireQuotation';
import ActionQuotation from './ActionQuotation/ActionQuotation';
import Loader from "../../../components/Loader";

class DetailDevis extends Component {


 componentDidMount() {
    this.props.getQuotation(this.props.match.params.id);
  }
    render() {

     const {loading} = this.props.quotation;

        return loading ? <Loader/> : (
            <Fragment>
              <DetailDevisHeader/>
                <div className="bloc_conseil rounded mb-3">
                    <b>💡 Bon à savoir :</b> Ce devis a été réalisé en fonction des informations fournies. Il pourra être amené à évoluer en fonction de votre projet final.
                </div>
              <NotaireQuotation/>
              <DetailQuotation/>
              <ActionQuotation/>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
  return {quotation : state.quotation };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getQuotation: quotationActions.getQuotation }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailDevis);
