import { quotationConstants } from '../constants/quotation.constants';

const quotationState = {
  loading: true,
  quotation: {},
  error: false,
}

export function Quotation(state = quotationState, action) {
  switch (action.type) {
    case quotationConstants.GETQUOTATION_REQUEST_LOADING:
      return {
        ...state,
        loading: true
      };
    case quotationConstants.GETQUOTATION_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        quotation: action.payload.data,
      }
      case quotationConstants.GETQUOTATION_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
    };
    default:
      return state
  }
}
