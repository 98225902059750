import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import NavBarSecondaryItem from './NavBarSecondaryItem';
import { history } from '../../helpers/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const NavBarItem = (props) => {

 function ifOnPath() {
    return history.location.pathname.match(props.link) ? true : false;
  }


  function getChildren(items){
   if(items && ifOnPath() === true ){
     return(
         <ul class="list-unstyled selected">
            {items.map((item) =>
              <NavBarSecondaryItem link={item.link} content={item.content}/>
             )}
         </ul>
      )
    }
  }

  return(
    <div>
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName='selected' exact={true} strict to={props.link} ><FontAwesomeIcon icon={props.icon} style={{fontWeight: "900 !important"}} className="icon"/>
            {props.content}
            </NavLink>
            {getChildren(props.submenu)}
        </li>
    </div>
    )
}


export default NavBarItem;
