import { folderConstants } from '../constants/folder.constants';
import { folderService } from '../services/folder.services';

export const folderActions = {
    getFolders,
};

// Get list of Quotations

function getFolders() {
    return dispatch => {
        dispatch(loading());

        folderService.getFolders()
            .then (
                response => {
                  console.log(response);
                    let folders = response.data
                    console.log("FOLDERS")
                    console.log(folders)
                    dispatch(success(folders));
                }
            ).catch (
                error => {
                  console.log(error)
                    dispatch(failure(error.response));
                }
        );
    };
}

function loading() {
    return {
        type: folderConstants.GETFOLDERS_REQUEST_LOADING
    }
}

function success(folders) {
    return {
        type: folderConstants.GETFOLDERS_REQUEST_SUCCESS,
        payload: {
            folders,
        }
    }
}

function failure(error) {
    return {
        type: folderConstants.GETFOLDERS_REQUEST_FAILURE,
        payload: {
            error
        }
    }
}
