import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';
import {connect} from "react-redux";

class DetailDevisHeader extends Component{

  render(){

    const {quotation, loading} = this.props.quotation
    return loading ? null : (
      <div className="mb-4">
        <div className="d-flex bd-highlight">
          <div className="bd-highlight p-2">
            <Link to="/" className="btn btn-light btn-lg rounded-circle my-auto"><FontAwesomeIcon icon="angle-left"/></Link>
          </div>
          <div className="bd-highlight p-2">
            <h2 className="mt-2">Devis de Maitre  {quotation.user.fiche.nom}</h2>
          </div>
          <div className="ml-auto bd-highlight p-2">
            <h2><span className="badge-category mt-2">{quotation.category}</span></h2>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {quotation : state.quotation };
}




export default connect(mapStateToProps, null)(DetailDevisHeader);



