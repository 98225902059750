import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import Title from "../../components/Title";

class ProfilPage extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const {user} = this.props;

        return (
            <Fragment>
                <Title title="Vos informations personnelles"/>
                <p><b>Nom : </b>{user.lastname}</p>
                <p><b>Prénom : </b>{user.firstname}</p>
                <p><b>Téléphone : </b>{user.phone_number}</p>
                <p><b>Mail : </b>{user.email}</p>
            </Fragment>

        )
    }
}

function mapState(state) {
    return state.authentication;
}

export default connect(mapState)(ProfilPage);
