import React, {Component, Fragment} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";

class OthersCostsDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        }
        this.toggleChange = this.toggleChange.bind(this)
    }

    toggleChange() {
        this.setState({ toggle: !this.state.toggle })
    }

    render() {

        return this.props.others_costs.length >= 1 ? (
            <Fragment>
                <div className="border-bottom py-2">
                    <div className="d-flex bd-highlight">
                        <div className="bd-highlight mr-2 my-auto">
                            <button
                                className="btn btn-light btn-sm rounded-circle"
                                onClick={this.toggleChange}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        this.state.toggle
                                            ? 'angle-down'
                                            : 'angle-right'
                                    }
                                />
                            </button>
                        </div>
                        <h5><span className="badge badge-other mt-2">Autres frais</span></h5>
                        {this.state.toggle ? null : (
                            <div className="ml-auto bd-highlight my-2">
                                <b>{this.props.others_costs.reduce((a, b) => a + b.amount, 0).toFixed(2)}€ HT</b>
                            </div>
                        )}
                    </div>
                </div>
                {
                    this.state.toggle ?  <Fragment>
                        {this.props.others_costs.map((other) =>
                            <div className="border-bottom py-2" key={other.id}>
                                <div className="d-flex align-items-center">
                                    <div className="mr-2">
                                        {other.libelle}
                                    </div>
                                    <div className="ml-auto">
                                        <b> {parseInt(other.amount, 10).toFixed(2)} </b>€ HT
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                        )}
                        <div className="border-bottom py-2">
                            <div className="d-flex align-items-center">
                                <div className="mr-2">
                                    <b>Total Autre frais</b>
                                </div>
                                <div className="ml-auto">
                                    <b>{this.props.others_costs.reduce((a, b) => a + b.amount, 0).toFixed(2)}€ </b>HT
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                    </Fragment> : null
                }
            </Fragment>
        ) : null
    }
}



function mapStateToProps(state) {
    const others_costs = state.quotation.quotation.other_cost_quotations
    return {others_costs};
}

export default connect(mapStateToProps, null)(OthersCostsDetail);

