import { requestQuotationConstants } from '../constants/request_quotation.constants';
import {quotationConstants} from "../constants/quotation.constants";


const initialState = {
  loading: false,
  requestQuotations: [],
  error: null
};

export function requestQuotations(state = initialState, action) {
  switch (action.type) {
    case requestQuotationConstants.LOADING:
    case quotationConstants.REFUSEQUOTATION_REQUEST_LOADING :
    case quotationConstants.ACCEPTQUOTATION_REQUEST_LOADING :
      return {
        ...state,
        loading: true
      };
    case requestQuotationConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        requestQuotations: action.payload.data
      };
    case quotationConstants.REFUSEQUOTATION_REQUEST_SUCCESS:
      state.requestQuotations.find(requestQuotation => requestQuotation.id === action.payload.data.idquotationresquest).quotations.splice(state.requestQuotations.find(requestQuotation => requestQuotation.id === action.payload.data.idquotationresquest).quotations.findIndex(quotation => quotation.id === action.payload.data.idquotation), 1)
      return {
        ...state,
        loading: false,
        error: null,
        requestQuotations: state.requestQuotations
      };
    case requestQuotationConstants.GET_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error

      };
    default:
      return state
  }
}
