import React, {Component, Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";

class ConsultingDetail extends Component{

    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        }
        this.toggleChange = this.toggleChange.bind(this)
    }

    toggleChange() {
        this.setState({ toggle: !this.state.toggle })
    }

  render(){
    return this.props.consulting.length >=1 ? (
      this.props.consulting.map((consulting)=>
        <Fragment key={consulting.id}>
            <div className="border-bottom py-2">
                <div className="d-flex bd-highlight">
                    <div className="bd-highlight mr-2 my-auto">
                        <button
                            className="btn btn-light btn-sm rounded-circle"
                            onClick={this.toggleChange}
                        >
                            <FontAwesomeIcon
                                icon={
                                    this.state.toggle
                                        ? 'angle-down'
                                        : 'angle-right'
                                }
                            />
                        </button>
                    </div>
                    <h5><span className="badge badge-info mt-2">Honoraires Conseil</span></h5>
                    {this.state.toggle ? null : (
                        <div className="ml-auto bd-highlight my-2">
                            <b>{consulting.number_hours * consulting.honoraire}€ HT</b>
                        </div>
                    )}
                </div>
            </div>
            {this.state.toggle ? <div className="border-bottom py-2">
                <div className="d-flex align-items-center">
                    <div className="mr-2">
                        <b>{consulting.number_hours} heure(s) de conseils - Honoraire: {consulting.honoraire}€/h</b>
                    </div>
                    <div className="ml-auto">
                        <b>{consulting.number_hours * consulting.honoraire}€ </b>HT
                    </div>
                </div>
                <div>
                </div>
            </div>: null}

     </Fragment>
     )
    ) : null
  }
}

function mapStateToProps(state) {
    const consulting = state.quotation.quotation.consulting_quotations
    return {consulting};
}

export default connect(mapStateToProps, null)(ConsultingDetail);



