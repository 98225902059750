import React, {Component} from 'react';
import {userActions} from "../../actions/auth";
import {connect} from "react-redux";
import Loader from "../../components/Loader";
import {Redirect} from "react-router-dom";



class Validate extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.login(this.props.match.params.token)
    }


    render() {

        const {loading, error} = this.props

        if (loading) {
            return (
                <div className="">
                    <Loader/>
                </div>
            )
        }

        if (error) {
            return (
                <Redirect to={{pathname: '/login'}}/>
            )
        }
        return (
            <div>

            </div>
        )
    }
}


function mapState(state) {
    return state.authentication;
}

const actionCreators = {
    login: userActions.login
};


export default connect(mapState, actionCreators)(Validate);

