import React, {Component} from 'react';
import moment from "moment";
import Card from "../../components/Cards";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {acceptQuotation, quotationActions, refuseQuotation} from "../../actions/quotation.actions";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar as faStarFull} from "@fortawesome/free-solid-svg-icons";
import {faStar} from "@fortawesome/free-regular-svg-icons";

class CardDemande extends Component {

    constructor(props) {
        super(props);

        this.state = {
            detail: false,
            detailAvis: false
        }

        this.detailChange = this.detailChange.bind(this)
        this.detailAvisChange = this.detailAvisChange.bind(this)
    }

    detailChange() {
        this.setState({detail: !this.state.detail})
    }

    detailAvisChange() {
        this.setState({detailAvis: !this.state.detailAvis})
    }


    refuseQuotationCall(idquotation, idquotationresquest) {
        this.props.refuseQuotation(idquotation, idquotationresquest)
    }


    acceptQuotationCall(id) {
        this.props.acceptQuotation(id)
    }

    render() {

        const {resquestQuotation} = this.props

        return (
            <Card>
                <div className="row mb-2">
                    <div className="col-6">
                        <h4 className="text-primary mb-3">
                            Demande du {moment(resquestQuotation.created_at).format('L')}
                        </h4>
                    </div>
                    <div className="col-6 text-right">
                        <span className="badge badge-category"
                              style={{fontSize: 18}}>{resquestQuotation.category.name}</span>
                    </div>
                </div>
                <div className="border-bottom pb-2">
                    {
                        this.state.detail ? <div className="col-12 pt-2">
                            {resquestQuotation.reponse_qualifications.map(reponse_qualification =>
                                <div key={reponse_qualification.id}>
                                    <b>{reponse_qualification.qualification.question}</b>
                                    <p>{reponse_qualification.reponse}</p>
                                </div>
                            )}
                        </div> : null
                    }
                    <button className="btn btn-sm btn-light"
                            onClick={this.detailChange}>{this.state.detail ? "Voir moins de détail" : "Voir les détail de la demande"}</button>
                </div>
                <div className="mt-2">
                    <h5>Vous avez {resquestQuotation.quotations.length} devis en attentes</h5>
                    {resquestQuotation.quotations.map(quotation =>
                        <div className="row my-2 border-bottom" key={quotation.id}>
                            <div className="col-6">
                                <p className="mb-1">Maitre {quotation.notaire.nom}</p>
                                <p>{[...Array(quotation.rating)].map(() =>
                                    <FontAwesomeIcon icon={faStarFull} style={{color: '#ffce00'}}/>
                                )}
                                    {[...Array(5 - quotation.rating)].map(() =>
                                        <FontAwesomeIcon icon={faStar} style={{color: '#ffce00'}}/>
                                    )} avec {quotation.note_size} avis <button className="btn btn-sm btn-light"
                                                                               onClick={this.detailAvisChange}>{this.state.detailAvis ? "Cacher les avis" : "Voir les avis"}</button>
                                </p>
                            </div>
                            <div className="col-6 text-right">
                                <p><b>Total :</b></p>
                                <p className="mb-1"><b>{quotation.total_ht.toFixed(2)}€ </b>HT</p>
                                <p><b>{quotation.total_ttc.toFixed(2)}€ </b>TTC</p>
                            </div>
                            {
                                this.state.detailAvis ?
                                    <div className="col-12">
                                        {quotation.avis.map(avis_one =>
                                            <div className="border rounded mb-2 p-2" key={avis_one.id}>
                                                <p className="mb-1">Sujet
                                                    d'intervention: <b>{avis_one.sujet_notation}</b><br/>
                                                    {avis_one.commentaire_notation}</p>
                                            </div>
                                        )}
                                    </div> : null
                            }

                            <div className="col-12">
                                {quotation.comment ?
                                    <div className="alert alert-secondary mt-3"><strong>Message du notaire:</strong>
                                        <i>{quotation.comment}</i></div> : null}
                            </div>
                            <div className="col-6 offset-6 text-right mb-2">
                                <button className="mr-1 btn btn-secondary btn-sm"
                                        onClick={() => this.acceptQuotationCall(quotation.id)}>Accepter le devis
                                </button>
                                <button className="mr-1 btn btn-danger btn-sm"
                                        onClick={() => this.refuseQuotationCall(quotation.id, resquestQuotation.id)}>Refuser le devis
                                </button>
                                <Link to={`/devis/${quotation.id}`} className="btn btn-primary btn-sm">Voir plus de
                                    détail</Link>
                            </div>
                        </div>
                    )}
                </div>
            </Card>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({refuseQuotation: quotationActions.refuseQuotation, acceptQuotation : quotationActions.acceptQuotation}, dispatch);
}

export default connect(null, mapDispatchToProps)(CardDemande);
