import React, {Component, Fragment} from 'react';
import Title from "../../components/Title";
import {connect} from "react-redux";
import {requestQuotationsActions} from "../../actions/request_quotations.actions";
import Loader from "../../components/Loader";
import CardDemande from "./CardDemande";


class ListeDemandes extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.props.getAll()
    }



    render() {
        const {requestQuotations, loading } = this.props

        if (loading) {
            return <Loader/>
        }

        return (
            <Fragment>
                <Title title="Liste des demandes"/>
                <div className="col-12">
                    <div className="bloc_conseil rounded mb-4">
                        <div>
                            <b>💡 Bon à savoir :</b>
                        </div>
                    </div>
                    {requestQuotations.map(resquestQuotation =>
                        <CardDemande key={resquestQuotation.id} resquestQuotation={resquestQuotation}/>
                    )}

                </div>
            </Fragment>
        )
    }
}
function mapState(state) {
    return state.request_quotations;
}

const actionCreators = {
    getAll: requestQuotationsActions.getAll
};


export default connect(mapState, actionCreators)(ListeDemandes);

