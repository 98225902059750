import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from "./Layout/Layout";
export const PrivateRoute = ({ component: Component, ...rest }) => (
            <Route {...rest} render={props => (
                localStorage.getItem('user')
                    ?<Layout>
                      <main className="dash-content col-12 col-md-9 col-xl-10" role="main">
                        <div className="py-4 px-1 px-sm-4">
                          <Component {...props} />
                        </div>
                      </main>
                    </Layout>
                    : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )} />
)
