import React, {Component, Fragment} from 'react';
import NavBar from '../NavBar/NavBar';
class Layout extends Component{
  render(){
    return(
      <Fragment>
        <div className="container-fluid">
          <div className="row">
           <NavBar/>
           {this.props.children}
          </div>
         </div>
      </Fragment>
    )
  }
}

export default Layout;
